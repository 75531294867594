import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom'
import Main from './views/Main';
import Statistics from './views/Statistics';
import axios from 'axios';
import Weeks from './views/Weeks';
import Community from './views/Community';

const Routes = ({ history }) => {
  const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3333' : 'https://steamapi.nikiahlskog.com';
  const [ownedGames, setOwnedGames] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingRecentGames, setLoadingRecentGames] = useState(true);
  const [recentGames, setRecentGames] = useState([]);
  const [loadingPlayerSummaries, setLoadingPlayerSummaries] = useState(true);
  const [playerSummaries, setPlayerSummaries] = useState([]);

  useEffect(() => {
    const getOwnedGames = async () => {
      if (sessionStorage.getItem('ownedGames')) {
        setOwnedGames(JSON.parse(sessionStorage.getItem('ownedGames')))
      } else {
        const request = await axios.get(`${url}/getOwnedGames`);
        sessionStorage.setItem('ownedGames', JSON.stringify(request.data));
        setOwnedGames(request.data);
      }
      setLoadingData(false);
    }
    getOwnedGames();

    const getRecentGames = async () => {
      if (sessionStorage.getItem('recentGames')) {
        setRecentGames(JSON.parse(sessionStorage.getItem('recentGames')))
      } else {
        const request = await axios.get(`${url}/getRecentGames`);
        sessionStorage.setItem('recentGames', JSON.stringify(request.data));
        setRecentGames(request.data);
      }
      setLoadingRecentGames(false);
    }
    getRecentGames();

    const getPlayerSummaries = async () => {
      if (sessionStorage.getItem('playerSummaries')) {
        setPlayerSummaries(JSON.parse(sessionStorage.getItem('playerSummaries')))
      } else {
        const request = await axios.get(`${url}/getPlayerSummaries`);
        sessionStorage.setItem('playerSummaries', JSON.stringify(request.data));
        setPlayerSummaries(request.data);
      }
      setLoadingPlayerSummaries(false);
    }
    getPlayerSummaries();
  }, [url]);

  return (
    <>
      <div id='router-content-area'>
        <Switch>
          <Route exact path='/' render={(history) => <Main {...history} playerSummaries={playerSummaries} ownedGames={ownedGames} loadingData={loadingData && loadingPlayerSummaries} history={history}/>} />
          <Route exact path='/twoweeks' render={(history) => <Weeks {...history} recentGames={recentGames} playerSummaries={playerSummaries} loadingData={loadingRecentGames} history={history} />} />
          <Route exact path='/charts' render={(history) => <Statistics {...history} ownedGames={ownedGames} loadingData={loadingData} history={history}/>} />
          <Route exact path='/community' render={(history) => <Community {...history} ownedGames={ownedGames} loadingData={loadingData} history={history} />} />
        </Switch>
      </div>
    </>
  );
}

export default Routes;