import React from 'react';
import styled from 'styled-components';
import { PacmanLoader } from 'react-spinners';
import Friend from '../components/Friend';
import Navigation from '../components/Navigation';
import Container from '../components/Container';

const LoaderDiv = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h3 {
    margin-top: 55px;
  }
`

const ContainerDiv = styled.div`
  margin-left: 64px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  grid-gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
`;

const Main = ({ history, ownedGames, loadingData, playerSummaries }) => {
  const loaderOverrideStyles = {
    marginLeft: '-20px'
  }

  return (
      <div className="main">
        <Navigation history={history}/>
        <Container>
          {!loadingData &&
            <h1>Steam pelidata vuodesta 2009 lähtien!</h1>
          }
        </Container>
        <ContainerDiv>
          {loadingData &&
            <LoaderDiv>
              <PacmanLoader color={'#A7D129'} loading={loadingData} css={loaderOverrideStyles} />
              <h3>Pureskellaan dataa, odottele rauhassa.</h3>
            </LoaderDiv>
          }

          {!loadingData &&
            ownedGames.map(fren =>
              <Friend fren={fren} key={fren.name} playerSummaries={playerSummaries} />
            )}
        </ContainerDiv>
      </div>
  );
}

export default Main;
