import React from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const Navigation = ({ history }) => {

  return (
      <SideNav
        onSelect={(selected) => {
          if (selected === 'home') {
            history.history.push('/');
          }
          if (selected === 'charts') {
            history.history.push('/charts')
          }
          if (selected === 'twoweeks') {
            history.history.push('/twoweeks')
          }
          if (selected === 'community') {
            history.history.push('/community')
          }
        }}
      >
        <SideNav.Toggle />
        <SideNav.Nav>
        <NavItem eventKey="home" active={window.location.pathname === '/' ? true : false}>
            <NavIcon>
              <i className="far fa-surprise" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
              Pelaajat
            </NavText>
          </NavItem>
        <NavItem eventKey="charts" active={window.location.pathname === '/charts' ? true : false}>
            <NavIcon>
              <i className="fas fa-chart-bar" style={{ fontSize: '1.75em' }} />
            </NavIcon>
            <NavText>
              Kaaviot
            </NavText>
          </NavItem>
        <NavItem eventKey="twoweeks" active={window.location.pathname === '/twoweeks' ? true : false}>
          <NavIcon>
            <i className="fas fa-gamepad" style={{ fontSize: '1.75em' }} />
          </NavIcon>
          <NavText>
            Kahden viikon pelit
            </NavText>
        </NavItem>
        <NavItem eventKey="community" active={window.location.pathname === '/community' ? true : false}>
          <NavIcon>
            <i className="fas fa-pager" style={{ fontSize: '1.75em' }} />
          </NavIcon>
          <NavText>
            Kaikkien yhteiset pelit
            </NavText>
        </NavItem>
        </SideNav.Nav>
      </SideNav>
  );
}

export default Navigation;
