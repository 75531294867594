import React from 'react';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

const StatisticsView = styled.div`
  color: white;
  margin-left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`

const MostGamesTooltip = styled.div`
  background-color: white;
  color: #A7D129;
  padding: 10px;
`

const Statistics = ({history, ownedGames}) => {
  const gamesData = ownedGames.map(fren => ({
    name: fren.name,
    games: fren.steamData.game_count
  }))
  const data = gamesData.sort((a, b) => b.games - a.games);

  const playDaysData = ownedGames.map(fren => {
    const minutes = fren && fren.steamData && fren.steamData.games && fren.steamData.games.reduce((a, b) => {
      return a + b.playtime_forever;
    }, 0).toFixed(2);
    const hours = Math.round((minutes / 60));
    const days = Math.round((hours / 24));
    return {
      name: fren.name,
      days
    }
  });

  const daysData = playDaysData.sort((a, b) => b.days - a.days);

  const renderMostGamesTooltip = (tooltip) =>
     <MostGamesTooltip>
      <p>Nimi: {tooltip.payload[0] && tooltip.payload[0].payload.name}</p>
      <p>Pelejä: {tooltip.payload[0] && tooltip.payload[0].payload.games}</p>
    </MostGamesTooltip>


  const renderMostPlayedTooltip = (tooltip) =>
    <MostGamesTooltip>
      <p>Nimi: {tooltip.payload[0] && tooltip.payload[0].payload.name}</p>
      <p>Pelannut: {tooltip.payload[0] && tooltip.payload[0].payload.days} päivää</p>
    </MostGamesTooltip>

  return (
    <div className="App">
    <Navigation history={history} />
    <StatisticsView>
    <h2>Eniten pelejä</h2>

        <ResponsiveContainer width="99%" aspect={3}>
        <BarChart
          width={1300}
          height={300}
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={renderMostGamesTooltip}/>
          <Bar dataKey="games" fill="#A7D129" />
        </BarChart> 
        </ResponsiveContainer>

        <h2>Eniten pelannut</h2>
        <ResponsiveContainer width="99%" aspect={3}>
        <BarChart
          width={1300}
          height={300}
          data={daysData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={renderMostPlayedTooltip}/>
          <Bar dataKey="days" fill="#A7D129" />
        </BarChart>
        </ResponsiveContainer>
    </StatisticsView>
    </div>
  );
}

export default Statistics;
