import React from 'react';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import FavoriteGames from '../components/FavoriteGames';
import Container from '../components/Container';

const StatisticsView = styled.div`
  margin-left: 64px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  grid-gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
`

const Weeks = ({history, recentGames, playerSummaries}) => {
  const playedGames = recentGames.map(fren => {
  const gameMinutes = fren.steamData && fren.steamData.games && fren.steamData.games.reduce((acc, b) => acc + b.playtime_2weeks, 0);
  const gameHours = Math.round((gameMinutes / 60));
  const gameDays = Math.round((gameHours / 24));

  return {
    name: fren.name,
    gamesAmount: fren.steamData && fren.steamData.games && fren.steamData.games.length,
    favouriteGame: fren.steamData && fren.steamData.games && fren.steamData.games.sort((a, b) => b.playtime_2weeks - a.playtime_2weeks),
    gameMinutes,
    gameHours,
    gameDays,
  }
}).sort((a, b) => b.gameMinutes - a.gameMinutes);

  const filterNan = playedGames.map(player => {
    if (isNaN(player.gameHours)) {
      return {
        ...player,
        gameDays: 0,
        gameHours: 0,
      };
    }
    return player;
  })
  const mostPlayed = filterNan.sort((a, b) => b.gameHours - a.gameHours);

  const StyledRenderTooltip = styled.div`
  background-color: white;
  color: #A7D129;
  padding: 10px;
`;

  const renderTooltip = (tooltip) =>
    <StyledRenderTooltip>
      <p>Nimi: {tooltip.payload[0] && tooltip.payload[0].payload.name}</p>
      <p>Pelitunnit: {tooltip.payload[0] && tooltip.payload[0].payload.gameHours}</p>
      <p>Pelipäivät: {tooltip.payload[0] && tooltip.payload[0].payload.gameDays}</p>
    </StyledRenderTooltip>

  return (
    <div className="App">
    <Navigation history={history} />
    <div>
      <Container>
      <h1>Eniten pelannut 2 viikon aikana</h1>
        <ResponsiveContainer width="99%" aspect={3}>
        <BarChart
          width={1300}
          height={300}
          data={mostPlayed}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={renderTooltip} />
          <Bar dataKey="gameHours" fill="#A7D129" />
        </BarChart>
        </ResponsiveContainer>
        <h2>Suosikkipelit 2 viikon aikana</h2>
      </Container>
        <StatisticsView>
          {playedGames.map(fren =>
            <FavoriteGames fren={fren} key={fren.name} playerSummaries={playerSummaries} />
          )}
        </StatisticsView>
      </div>
    </div>
  );
}

export default Weeks;
