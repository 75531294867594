import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-gap: 10px;
background-color: #222831;
color: white;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.7);
h2 {
  font-size: 32px;
  margin: 0px
}
.highlight {
    color: #A7D129;
    font-weight: bold;
}

img{
    justify-self: center;
    border-radius: 50%;
    width:150px;
    height:150px;
    border: solid 5px #A7D129;
    box-shadow: 0px 0px 30px rgb(167, 209, 41);
}
p {
  margin: 0px;
}
.gamelist {
  padding-left: 15px;
}
`

const FavoriteGames = ({ fren, playerSummaries }) => {
  const [summary] = playerSummaries.find(player => player.name === fren.name).steamData.players

  return (
    <StyledDiv>
      <img src={summary.avatarfull} alt="avatar" />
      <h2>{fren.name}</h2>
      <p><strong>Suosikkipeli: </strong> <span className="highlight">{fren.favouriteGame && fren.favouriteGame[0].name || 'Noob'}</span></p>
      <p><strong>Suosikkipeliä pelattu tunteina:</strong> <span className="highlight">{(fren.favouriteGame && Math.round(fren.favouriteGame[0].playtime_2weeks / 60)) || 'Noob'}</span></p>
      <p><strong>Keskimäärin pelattu päivässä tunteina:</strong> <span className="highlight">{fren.gameHours && (fren.gameHours / 14).toFixed(2) || 'Noob'}</span></p>
      <div><strong>Kaikki pelit 2 viikon ajalta:</strong>
      <ul className="gamelist">
        {fren.favouriteGame && fren.favouriteGame.map(game =>
            <li key={game.name} className="highlight">{game.name}</li>
        )}
      </ul>
      </div>
    </StyledDiv>
  );
}

export default FavoriteGames;
