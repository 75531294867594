import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.aside`
display: grid;
grid-template-columns: 1fr;
grid-gap: 10px;
background-color: #222831;
color: white;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.7);
h2 {
  font-size: 32px;
  margin: 0px
}
.highlight {
    color: #A7D129;
    font-weight: bold;
}

img{
    justify-self: center;
    border-radius: 50%;
    width:150px;
    height:150px;
    border: solid 5px #A7D129;
    box-shadow: 0px 0px 30px rgb(167, 209, 41);
}
p {
  margin: 0px;
}
`

const Friend = ({ fren, playerSummaries }) => {
  const [summary] = playerSummaries.find(player => player.name === fren.name).steamData.players
  const minutes = fren && fren.steamData && fren.steamData.games && fren.steamData.games.reduce((a, b) => {
    return a + b.playtime_forever;
  }, 0).toFixed(2);
  const hours = Math.round((minutes / 60));
  const days = Math.round((hours / 24));

  const favouriteGame = fren && fren.steamData && fren.steamData.games && fren.steamData.games.sort((a, b) => b.playtime_forever - a.playtime_forever);

  return (
      <StyledDiv>
        <img src={summary.avatarfull} alt="avatar" />
        <h2>{fren.name}</h2>
        <p><strong>Pelejä steamissa:</strong> <span className="highlight">{fren.steamData.game_count}</span></p>
        <p><strong>Kokonaispeliaika tunteina:</strong> <span className="highlight">{hours}</span></p>
        <p><strong>Kokonaispeliaika päivinä:</strong> <span className="highlight">{days}</span></p>
        <p><strong>Eniten pelattu peli:</strong> <span className="highlight">{favouriteGame[0].name}</span></p>
      </StyledDiv>
  );
}

export default Friend;
